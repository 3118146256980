let tags = $('#tags').select2({
    tags: true,
    width: '100%'
});

tags.on('select2:unselecting', function(e) {
    if ( $(e.params.args.originalEvent.target).hasClass('select2-results__option') ) {
        e.preventDefault();
    }
});


let userEditTags = $('#userEditTags').select2({
    tags: true,
    width: '100%'
});

userEditTags.on('select2:unselecting', function(e) {
    if ( $(e.params.args.originalEvent.target).hasClass('select2-results__option') ) {
        e.preventDefault();
    }
});
