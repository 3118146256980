function createNewEmergencyContactInput() {
  // current length is equal to new index that we need
  let index = $('#slidein-box-edit .js-emergency-contact').length;
  let clone = $('#slidein-box-edit .js-emergency-contact').first().clone();

  clone.find('.js-emergency-contact-remove').show();
  clone.find('.js-emergency-contact-name').val('').attr('name', `emergency_contacts[${index}][name]`);
  clone.find('.js-emergency-contact-lastname').val('').attr('name', `emergency_contacts[${index}][lastname]`);
  clone.find('.js-emergency-contact-mobile').val('').attr('name', `emergency_contacts[${index}][mobile]`);
  clone.find('.js-emergency-contact-relationship').val('').attr('name', `emergency_contacts[${index}][relationship]`);

  $('#slidein-box-edit .js-emergency-contact-list').append(clone);
}

window.createNewEmergencyContactInput = createNewEmergencyContactInput;

$('#slidein-box-edit .js-emergency-contact-list').on('click', '.js-emergency-contact-remove', (e) => {
  $(e.target).closest('.js-emergency-contact').remove();
});

$('#slidein-box-edit .js-add-additional-emergency-contact').on('click', () => createNewEmergencyContactInput());