function buildFormData(formData, data, parentKey) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach(key => {
        buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
}

function objectToFormData(data) {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
}


function preloadImage(image) {
    let img = new Image();
        img.src = image;

    return img;
}


function updateUrl(filters) {
  let updatedUrl = window.location.pathname;
  let urlParams = new URLSearchParams();

  let index = 0;

  for (let key in filters) {
      if ( filters[key] !== '' && filters[key] !== null ) {
        if ( index === 0 ) {
          updatedUrl += '?';
        }

        urlParams.set(key, filters[key]);

        index++;
      }
  }

  window.history.pushState(null, null, updatedUrl + urlParams.toString());
}

function appendQueryToURL(url, params) {
  let index = 0;

  for (let param in params) {
    if ( params[param] !== '' && params[param] !== null ) {
      if ( index === 0 ) {
        url += "?";
      } else {
        url += '&';
      }

      url += param + '=' + params[param];
      index++;
    }
  }

  return url;
}

function nl2br(text) {
  return text.replace(/(?:\r\n|\r|\n)/g, '<br>');
}



function updateQueryString( queryParams ) {
    let updatedUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;

    let urlParams = new URLSearchParams(window.location.search);

    for (const key in queryParams) {
      if ( queryParams[key] === '' || queryParams[key] === null ) {
        urlParams.delete(key);
      } else {
        urlParams.set(key, queryParams[key]);
      }
    }

    if ( urlParams.toString() !== '' ) {
      updatedUrl += '?';
    }

    window.location = updatedUrl + urlParams.toString();
}

window.updateQueryString = updateQueryString;

window.objectToFormData = objectToFormData;
window.preloadImage = preloadImage;
window.updateUrl = updateUrl;
window.appendQueryToURL = appendQueryToURL;
window.nl2br = nl2br;