function createNewEducationInput() {
  // current length is equal to new index that we need
  let index = $('#slidein-box-edit .js-education').length;
  let clone = $('#slidein-box-edit .js-education').first().clone();

  clone.find('.js-education-remove').show();
  clone.find('.js-education-title').val('').attr('name', `educations[${index}][title]`);

  $('#slidein-box-edit .js-education-list').append(clone);
}

window.createNewEducationInput = createNewEducationInput;

$('#slidein-box-edit .js-education-list').on('click', '.js-education-remove', (e) => {
  $(e.target).closest('.js-education').remove();
});

$('#slidein-box-edit .js-add-additional-education').on('click', () => createNewEducationInput());
